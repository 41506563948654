exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dict-json-lang-about-index-jsx": () => import("./../../../src/pages/{DictJson.lang}/about/index.jsx" /* webpackChunkName: "component---src-pages-dict-json-lang-about-index-jsx" */),
  "component---src-pages-dict-json-lang-contact-index-jsx": () => import("./../../../src/pages/{DictJson.lang}/contact/index.jsx" /* webpackChunkName: "component---src-pages-dict-json-lang-contact-index-jsx" */),
  "component---src-pages-dict-json-lang-index-jsx": () => import("./../../../src/pages/{DictJson.lang}/index.jsx" /* webpackChunkName: "component---src-pages-dict-json-lang-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-index-template-jsx": () => import("./../../../src/templates/blog-index-template.jsx" /* webpackChunkName: "component---src-templates-blog-index-template-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/blog-template.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */)
}

